.main-carousel {
    height: 100%;

    .flickity-viewport {
        overflow: visible;

        .flickity-slider {
            z-index: -1;
        }
    }

    .flickity-page-dots {
        bottom: -100px;
    }

    .flickity-prev-next-button.previous {
        left: -60px;
    }

    .flickity-prev-next-button.next {
        right: -60px;
    }
}

.carousel-cell {
    width: 100%;
    height: 100%;
    margin-right: 70px;
    background-size: contain;
    border-radius: 16px;
    counter-increment: carousel-cell;

    &:not(.is-selected) {
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }
}

.dm-width {
    width: 300px;
    margin: 0 auto;

    .caption {
        color: #000;
        font-size: 16px;
        font-weight: 700;
    }

    .dm-device {
        position: relative;
        width: 100%;
        padding-bottom: 203.477897%;
        margin-bottom: 20px;

        .device {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            -webkit-background-size: 100% 100%;
            background: url("../../../public/assets/images/pixel8-mock.png") no-repeat center center;
            background-size: contain;
        }
    }

    @media screen and (max-width: 480px) {
        scale: 0.8;
    }
}

.screen {
    position: absolute;
    top: 14px;
    bottom: 14px;
    left: 18px;
    right: 20px;
}