.toast {
    background-color: #f6f6f6;

    .toast-message {
        color: #1d1d1d;
    }

    &-success {
        background-color: #c8edd3;
        border-color: #04621f;
        color: #04621f;
    }

    &-info {
        background-color: #EFF8FF;
        border-color: #1D5E7F;
        color: #1D5E7F;
    }

    &-error {
        background-color: #FFEDEA;
        border-color: #FFB4AC;
        color: $error-color;
    }
}