
$font-family-primary: "Open Sans", sans-serif !important;
$font-family-secondary: "Manrope", sans-serif;

$base-font-size: 1rem;

$font-size-xs: 0.69rem;
$font-size-sm: 0.83rem;
$font-size-md: $base-font-size;
$font-size-lg: 1.2rem;
$font-size-xl: 1.44rem;
$font-size-2xl: 1.73rem;
$font-size-3xl: 2.07rem;
$font-size-4xl: 2.49rem;
$font-size-5xl: 2.99rem;

$html-font-size: $font-size-md;
$body-font-size: $font-size-md;

$body-secondary-color: $gray;