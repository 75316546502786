* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: $html-font-size;
}

body {
    background: #fff;
    font: $body-font-size $font-family-primary;
    scroll-snap-type: y mandatory;

    h2+div {
        margin-bottom: 2rem;
    }

    h3 {
        color: #161C2D;
    }

    h4 {
        color: #525252;
    }

    p {
        color: #2B2D2C;
    }
}

.btn {
    border-radius: 50px;
    padding: 0.5rem 1rem;
}

a {
    &:hover {
        text-decoration: underline;
    }
}

@media print {
    body {
        display: none
    }
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    [class*="col-"] {
        width: 100%;
    }
}

@media screen and (min-width: 1280px) {
    #CybotCookiebotDialog.CybotEdge {
        padding: $space-8 $space-24 !important;

        .CybotCookiebotDialogBodyBottomWrapper {
            margin-top: 0 !important;
        }
    }

    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        padding: $space-8 $space-16 !important;
    }
}