.accordion .accordion-item {
    border: 1px solid #D9D9D9;;
    border-left-width: 4px;
    border-left-color: #73B6BB;
    border-radius: 8px;

    &:not(:last-child) {
        margin-bottom: $space-16;
    }

    .accordion-item:not(:first-of-type) {
        border-top: 1px solid #D9D9D9;
    }

    .accordion-button {
        border-radius: 8px;
    }
}