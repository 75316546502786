canvas#myThreeJsCanvas {
    width: 100% !important;
    height: auto !important;
}

.button-set {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    gap: 1rem;
    padding: 1rem;
}

.block-ui {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .spinner-border {
        --bs-spinner-width: 5rem;
        --bs-spinner-height: 5rem;
        --bs-spinner-border-width: 0.25rem;
    }
}