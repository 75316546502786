section {
    padding-top: $space-64;
    padding-bottom: $space-64;
    scroll-snap-align: start;

    @media screen and (max-width: 768px) {
        padding: $space-32 0;
    }

    >h2 {
        text-align: center;
        font-weight: 700;
    }

    >.section-caption {
        display: flex;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        color: #2C3D56;
        font-size: 14px;
        margin-bottom: 0.5rem;
    }
}

#hero {
    background-image: url("../../../public/assets/images/background-pattern.png");
    background-color: #E3F3FF;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    position: relative;
    z-index: 0;
    padding-top: 10rem;
    /* Add animation properties */
    animation: fadeIn 1s forwards;

    @media screen and (max-width: 992px) {
        padding-top: 6rem;
    }

    @media screen and (max-width: 768px) {
        padding: 5rem 0 4rem;
    }

    @media screen and (max-width: 480px) {
        padding-bottom: 0;
    }

    .hero-child {
        margin-top: 6rem;
    }

}

#features {
    background-color: transparent;
}


#team,
#dsWorld {
    background-color: #F0F4FA;
}

#benefits {
    .card-title {
        font-size: 16px;
        font-weight: 700;
    }
}

#registration,
#download {
    background-color: #4498c2;
    background-image: url("../../../public/assets/images/footer-logo-vector.svg");
    background-repeat: no-repeat;

    @media screen and (max-width: 480px) {
        background-blend-mode: lighten;
    }

    .qr-code {
        mix-blend-mode: darken;
    }
}

footer {
    background-color: darken(#4498c2, 10%);
    color: white;
    padding: 0.5rem;

    .navbar {
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            justify-content: center;
            gap: 1rem;
        }

        .nav-item-menu .nav-link {
            color: white;
        }
    }

    .footer-copyrights {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            font-size: 14px;
            color: $white;
            text-decoration: none;

            &:hover {
                color: #BACDE8;
                text-decoration: underline;
            }
        }
    }
}

:root {
    --animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --animation-duration: 1s;
}

@keyframes slideInUp {
    0% {
        opacity: 0;
        transform: translateY(25%);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes slideInDown {
    0% {
        opacity: 0;
        transform: translateY(-25%);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes flipInY {
    0% {
        opacity: 0;
        transform: perspective(90vw) rotateY(67.50deg);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.slideInUp {
    animation-name: slideInUp;
}

.slideInDown {
    animation-name: slideInDown;
}

.zoomIn {
    animation-name: zoomIn;
}

.flipInY {
    animation-name: flipInY;
}

.fadeIn {
    animation-name: fadeIn;
}

@media (prefers-reduced-motion: no-preference) {
    .zoom-in-animation {
        animation: zoomIn 1s 1;
    }

    .flip-animation {
        animation: flipInY 1s 1;
    }

    .slide-in-up-animation {
        animation: slideInUp 1s 1;
    }

    .slide-in-down-animation {
        animation: slideInDown 1s 1;
    }

    .fade-in-animation {
        animation: fadeIn 1s 1;
    }
}

.animation-transition {
    opacity: 1;
    transform: none;
}