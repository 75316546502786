@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import "_colors.scss";
@import "_font.scss";
@import "_spacing.scss";


// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "/node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$font-family: $font-family-primary;
$font-sans-serif: $font-family-primary;
$body-bg: #ffffff;
$body-color: #111;
$secondary-text-emphasis: #333;
$border-color: #D9D9D9;
$border-radius: 8px;
$headings-color: $heading-color-light;
$danger: $error-color;

$btn-color: #000;

$enable-grid-classes: false;
$enable-cssgrid: true;

$bs-navbar-toggler-border-color: transparent;
$navbar-toggler-focus-width: 2px;

$h1-font-size: $font-size-5xl;
$headings-font-family: $font-family-secondary;;
$headings-line-height: 1.125;

$h2-font-size: $font-size-4xl;
$h3-font-size: $font-size-3xl;
$h4-font-size: 24px;
$h5-font-size: 20px;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "/node_modules/bootstrap/scss/maps";
@import "/node_modules/bootstrap/scss/mixins";
@import "/node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "/node_modules/bootstrap/scss/utilities";
@import "/node_modules/bootstrap/scss/reboot";
@import "/node_modules/bootstrap/scss/type";
@import "/node_modules/bootstrap/scss/images";
@import "/node_modules/bootstrap/scss/containers";
@import "/node_modules/bootstrap/scss/grid";
@import "/node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "/node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here