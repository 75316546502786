.manrope-light {
    font-family: $font-family-secondary;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.manrope-normal {
    font-family: $font-family-secondary;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.manrope-medium {
    font-family: $font-family-secondary;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}


.manrope-semibold {
    font-family: $font-family-secondary;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.manrope-bold {
    font-family: $font-family-secondary;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

@media (max-width: 768px) {
    body {
        font-size: 14px;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    h5,
    h6 {
        font-size: 16px;
    }
}