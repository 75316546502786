@import "../../styles/variables/variables";

.navbar-nav {
    --bs-nav-link-hover-color: #146AFF;
}

// Customizations

.navbar {
    transition: all 400ms ease-in-out;
    padding: 0.5rem 6.25rem;

    @media (max-width: 992px) {
        padding: 0.5rem 1rem;
    }

    &.scroll,
    &.show {
        background-color: #fff;
        box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
    }

    .nav-item-menu {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;

        .nav-link {
            color: #10414F;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.navbar-brand {
    max-height: 32px;
    max-width: 200px;
    min-width: 120px;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}