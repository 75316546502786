.step {

    &-header {
        margin-bottom: $space-40;
        text-align: center;

        &-number {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            min-width: 40px;
            color: $white;
            border-radius: 50px;
            margin-bottom: $space-24;
        }

        &-title {
            color: $primary;
            font-size: 24px;
        }

        p {
            margin: 0;
        }
    }

    &-body {
        padding: 2rem;
        background-repeat: no-repeat;
        background-position: bottom right;
        border-radius: 1rem;

        .card-body {
            text-align: start;
            flex-grow: 0;

            .card-title {
                margin-bottom: $space-8;
                color: $body-color;
                font-size: 20px;
                font-weight: normal;
            }
        }

        hr {
            margin: 1.5rem 0;
        }
    }


    &:nth-child(1) {

        .step-header {
            &-number {
                background-color: #1D5E7F;
            }

            &-title {
                color: #1D5E7F;
            }
        }

        .step-body {
            background-color: #E7F4FB;
            background-image: url("../../../public/assets/images/step-1-cloud.svg");
        }

        hr {
            border-color: $primary !important;
        }
    }

    &:nth-child(2) {
        .step-header {
            &-number {
                background-color: #0F6169;
            }

            &-title {
                color: #0F6169;
            }
        }

        .step-body {
            background-color: #E1F4F5;
            background-image: url("../../../public/assets/images/step-2-cloud.svg");
        }

        hr {
            border-color: #43BAC5 !important;
        }
    }

    &:nth-child(3) {
        .step-header {
            &-number {
                background-color: #AC5A31;
            }

            &-title {
                color: #AC5A31;
            }
        }

        .step-body {
            background-color: #FCEEE0;
            background-image: url("../../../public/assets/images/step-3-cloud.svg");
        }

        hr {
            border-color: #FC8F58 !important;
        }
    }

   + .step-line {
        width: 100%;
        border: 1px dashed #555 !important;
        position: absolute;
        top: $space-4;
        left: 0;
        right: 0;
        z-index: -1;
    }

    @media screen and (max-width: 768px) {
        &-header {
            display: flex;
            text-align: start;
            gap: $space-16;
            margin-bottom: $space-8;
    
            &-number {
                margin: 0;
            }
        }

        &-body {
            padding: $space-16;
        }

        hr {
            margin: $space-16 0;
        }

        + .step-line {
            display: none;
        }
    }

}
